import ICoordenadorDeNucleo from '@/models/entities/ICoordenadorDeNucleo';
import IDiretriz from '@/models/entities/IDiretriz';
import IEntidade from '@/models/entities/IEntidade';
import { useLoginStore } from 'pioneira-ui';
import api from './apiEntidades';

class AuthService {
  getUsuarioPermissoes(): string[] {
    const uiStore = useLoginStore();
    return JSON.parse(uiStore.getUser().permissoes);
  }

  usuarioPossuiPermissao(permissoesRequeridas: string | string[]): boolean {
    const permissoesUsuario = this.getUsuarioPermissoes();
    const prefixo = 'fundosocial_0101_';

    if (typeof permissoesRequeridas === 'string') {
      if (permissoesRequeridas === '*') return true;
      return permissoesUsuario.includes(prefixo + permissoesRequeridas);
    }

    return permissoesRequeridas.some(permissao =>
      permissoesUsuario.includes(prefixo + permissao)
    );
  }
  async login(cnpj: string, numero_da_conta: string, codigo: string, responsavel_id: string): Promise<void> {
    const response = await api.post('/api/auth/entidade-login', {
      cnpj: cnpj,
      numero_da_conta: numero_da_conta,
      codigo: codigo,
      responsavel_id: responsavel_id
    });

    if (response && response.data && response.data.entidade) {
      this.updateLocalEntidadeData(response.data.entidade);
      this.updateLocalDiretrizData(response.data.diretriz);
    }
  }

  async fetchCSRFToken() {
    const csrfResponse = await api.get('/api/auth/csrf-token');
    api.defaults.headers.common['X-CSRF-TOKEN'] = csrfResponse.data.csrf_token;
  }

  async getContactLogin(cnpj: string, numero_da_conta: string) {
    const response = await api.post(`/api/auth/entidade-contact-options`, {
      cnpj: cnpj,
      numero_da_conta: numero_da_conta
    });

    return response.data;
  }

  async sendCode(cnpj: string, numero_da_conta: string, type: string, responsavel_id: number, contato_id: number) {
    const response = await api.post('/api/auth/get-codigo-login', {
      cnpj: cnpj,
      numero_da_conta: numero_da_conta,
      type: type,
      responsavel_id: responsavel_id,
      contato_id: contato_id
    });
    return response.data;
  }

  async serverLogout(loggedType: string): Promise<void> {
    await api.post(`/api/auth/${loggedType}-logout`);
  }

  async checkLoginEntidade() {
    try {
      const response = await api.get('/api/auth/entidade-check');
      return response.data.authenticated;
    } catch (error) {
      return false;
    }
  }

  async checkLoginCoordenador() {
    try {
      const response = await api.get('/api/auth/coordenador-check');
      return response.data.authenticated;
    } catch (error) {
      return false;
    }
  }

  async requestChangePasswordLink(email: string): Promise<void> {
    await api.post('/api/auth/request-reset-coordenador', {
      email
    });
  }

  async confirmChangePassword(email: string, token: string, password: string, password_confirmation: string): Promise<void> {
    await api.post('/api/auth/confirm-reset-coordenador', {
      email,
      token,
      password,
      password_confirmation
    });
  }

  async loginCoordenador(email: string, password: string): Promise<void> {
    const response = await api.post('/api/auth/coordenador-login', {
      email,
      password,
    });

    if (response && response.data && response.data.coordenador) {
      this.updateLocalCoordenadorData(response.data.coordenador);
      this.updateLocalDiretrizData(response.data.diretriz);
    }
  }

  cleanStoredLocalData(): void {
    this.removeLocalEntidadeData();
    this.removeLocalCoordenadorData();
    this.removeTokenData();
    window.location.href = `${
      process.env.VUE_APP_PUBLIC_PATH
        ? `/${process.env.VUE_APP_PUBLIC_PATH}`
        : ''
    }/login`;
  }

  async logout(): Promise<void> {
    await this.serverLogout(this.getLoggedType());
    this.cleanStoredLocalData();
  }

  adminLogout(): void {
    this.removeLocalEntidadeData();
    this.removeTokenData();
    //window.location.href = '/admin/login';
  }

  getDiretrizVigenteData(): IDiretriz {
    return JSON.parse(localStorage.getItem('diretriz'));
  }

  getLoggedAdminAreaUserData() {
    const loginStore = useLoginStore();
    const user = loginStore.getUser();

    // Verifica se o objeto 'user' está vazio
    if (Object.keys(user).length === 0 && user.constructor === Object) {
      return null;
    }

    return user;
  }

  getLoggedType(): string {
    if (this.getLoggedAdminAreaUserData() != null) {
      return 'admin';
    }
    if (this.getLoggedEntidadeData() != null) {
      return 'entidade';
    }
    if (this.getLoggedCoordenadorData() != null) {
      return 'coordenador';
    }

    return null;
  }

  getLoggedCoordenadorData(): ICoordenadorDeNucleo | null {
    const coordenadorFromStorage = localStorage.getItem('coordenador');

    if (coordenadorFromStorage) {
      return JSON.parse(coordenadorFromStorage);
    }

    return null;
  }

  getLoggedEntidadeData(): IEntidade | null {
    const uiStore = useLoginStore();
    const entidadeFromStore = uiStore.user;

    if (entidadeFromStore) {
      return entidadeFromStore as IEntidade;
    }

    const entidadeFromStorage = localStorage.getItem('entidade');

    if (entidadeFromStorage) {
      return JSON.parse(entidadeFromStorage);
    }

    return null;
  }

  updateLocalDiretrizData(diretriz: IDiretriz): void {
    localStorage.setItem('diretriz', JSON.stringify(diretriz));
  }

  updateLocalEntidadeData(user: IEntidade): void {
    localStorage.setItem('entidade', JSON.stringify(user));
    const uiStore = useLoginStore();
    uiStore.user = user;
  }

  updateLocalCoordenadorData(user: ICoordenadorDeNucleo): void {
    localStorage.setItem('coordenador', JSON.stringify(user));
    const uiStore = useLoginStore();
    uiStore.user = user;
  }

  private removeLocalEntidadeData(): void {
    //const uiStore = useLoginStore();
    //uiStore.user = null;
    localStorage.removeItem('entidade');
  }

  private removeLocalCoordenadorData(): void {
    const uiStore = useLoginStore();
    uiStore.user = null;
    localStorage.removeItem('coordenador');
    localStorage.removeItem('lastedProjectFilter');
  }

  private removeTokenData(): void {
    const uiStore = useLoginStore();
    localStorage.removeItem('token');
    localStorage.removeItem('lastedProjectFilter');
    //uiStore.token = null;
    uiStore.logout();
  }
}

export default new AuthService();
