import { createApp } from 'vue';
import { vMaska } from 'maska';
import App from './App.vue';
import router from './router';
import store from './store';
import { loadFonts } from './plugins/webfontloader';
import { uiPlugin } from 'pioneira-ui';
import '@/assets/global-styles.scss';
import { createPinia } from 'pinia';
import { setupCalendar } from 'v-calendar';

loadFonts();

const pinia = createPinia();

createApp(App)
  .use(pinia)
  .use(uiPlugin, {
    apiURL: process.env.VUE_APP_BASE_API,
    loginStore: {
      publicRoutes: ['/login', '/admin/login', '/login-callback'],
      loginRouteName: 'LoginAdmin',
      router: router,
    },
  })
  .use(setupCalendar, {})
  .use(store)
  .use(router)
  .directive('maska', vMaska)
  .mount('#app');
