import {
  createRouter,
  createWebHistory,
  RouteLocationNormalizedLoaded,
} from 'vue-router';
import { useLoginStore } from 'pioneira-ui';

import LoginCallbackView from '../views/admin/LoginCallbackView.vue';
import AuthService from '@/services/AuthService';

const authRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/login-callback',
    name: 'LoginCallback',
    component: LoginCallbackView,
    props: (route: RouteLocationNormalizedLoaded) => ({
      name: route.query.name,
      authCode: route.query.auth_code,
    }),
  },
  {
    path: '/admin/login',
    name: 'LoginAdmin',
    component: () => import('../views/admin/LoginView.vue'),
    props: (route: RouteLocationNormalizedLoaded) => ({
      name: route.query.name,
      authCode: route.query.auth_code,
    }),
  },
];

const appRoutes = [
  {
    path: '/',
    name: 'Base',
    redirect: { path: 'home' },
    meta: {
      guards: [],
      breadcrumb: [],
    },
    component: () => import('../views/BaseView.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        meta: {
          guards: ['entidade', 'coordenador'],
          breadcrumb: [{ name: 'Home' }],
        },
        component: () => import('../views/Home.vue'),
      },
      {
        path: '/projetos',
        name: 'Projetos',
        meta: {
          guards: ['entidade'],
          breadcrumb: [{ name: 'Home', path: '/home' }, { name: 'Projetos' }],
        },
        component: () => import('../views/projetos/ListProject.vue'),
      },
      {
        path: '/declaracoes',
        name: 'Declarações',
        meta: {
          guards: ['entidade'],
          breadcrumb: [
            { name: 'Home', path: '/home' },
            { name: 'Declarações' },
          ],
        },
        component: () => import('../views/declaracoes/ListDeclaration.vue'),
      },
      {
        path: '/projetos/cadastrar',
        name: 'Cadastrar novo projeto',
        meta: {
          guards: ['entidade'],
          breadcrumb: [
            { name: 'Home', path: '/home' },
            { name: 'Projetos', path: '/projetos' },
            { name: 'Cadastrar novo projeto' },
          ],
        },
        component: () => import('../views/projetos/FormProject.vue'),
      },
      {
        path: '/projetos/editar/:id',
        name: 'Editar projeto',
        meta: {
          guards: ['entidade'],
          breadcrumb: [
            { name: 'Home', path: '/home' },
            { name: 'Projetos', path: '/projetos' },
            { name: 'Editar projeto' },
          ],
        },
        component: () => import('../views/projetos/FormProject.vue'),
        props: true,
      },
      {
        path: '/declaracoes/projeto/:id',
        name: 'Emitir declaração para projeto',
        meta: {
          guards: ['entidade'],
          breadcrumb: [
            { name: 'Home', path: '/home' },
            { name: 'Declarações', path: '/declaracoes' },
            { name: 'Emitir declaração para projeto' },
          ],
        },
        component: () => import('../views/declaracoes/FormDeclaration.vue'),
        props: true,
      },
      {
        path: '/declaracoes/visualizar/:id',
        name: 'Visualizar declaração ',
        meta: {
          guards: ['entidade'],
          breadcrumb: [
            { name: 'Home', path: '/home' },
            { name: 'Declarações', path: '/declaracoes' },
            { name: 'Visualizar declaração' },
          ],
        },
        component: () => import('../views/declaracoes/FormDeclaration.vue'),
        props: true,
      },
      {
        path: '/atas',
        name: 'Atas',
        meta: {
          guards: ['entidade'],
          breadcrumb: [{ name: 'Home', path: '/home' }, { name: 'Atas' }],
        },
        component: () => import('../views/atas/ListMinute.vue'),
      },
      {
        path: '/comprovacoes',
        name: 'Comprovações',
        meta: {
          guards: ['entidade'],
          breadcrumb: [
            { name: 'Home', path: '/home' },
            { name: 'Comprovações' },
          ],
        },
        component: () =>
          import('../views/comprovacoes/ListPaymentEvidence.vue'),
      },
      {
        path: '/relatorios',
        name: 'Relatórios',
        meta: {
          guards: ['entidade'],
          breadcrumb: [{ name: 'Home', path: '/home' }, { name: 'Relatórios' }],
        },
        component: () => import('../views/relatorios/ListReport.vue'),
      },
      {
        path: '/coordenador/listagem-projetos',
        name: 'ProjetosCoordenador',
        meta: {
          guards: ['coordenador'],
          breadcrumb: [
            { name: 'Home', path: '/home', alias: 'Home' },
            { name: 'ProjetosCoordenador', alias: 'Projetos' },
          ],
        },
        component: () =>
          import('../views/coordenador/projetos/ListProjetos.vue'),
      },
      {
        path: '/coordenador/listagem-projetos/detalhes/:id',
        name: 'Detalhes Projetos Coordenador',
        meta: {
          guards: ['coordenador'],
          breadcrumb: [
            { name: 'Home', path: '/home', alias: 'Home' },
            {
              name: 'ProjetosCoordenador',
              path: '/coordenador/listagem-projetos',
              alias: 'Projetos',
            },
            {
              name: 'Detalhes Projetos Coordenador',
              alias: 'Detalhes Projetos',
            },
          ],
        },
        component: () =>
          import('../views/admin/listagem-projetos/FormListagemProjetos.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/admin',
    name: 'BaseAdmin',
    redirect: { path: '/admin/home' },
    meta: {
      guards: [],
      breadcrumb: [],
    },
    component: () => import('../views/admin/BaseView.vue'),
    children: [
      {
        path: '/admin/home',
        name: 'HomeAdmin',
        meta: {
          guards: ['admin'],
          permissions: "*",
          breadcrumb: [{ name: 'HomeAdmin', alias: 'Home' }],
        },
        component: () => import('../views/admin/Home.vue'),
      },
      {
        path: '/admin/agencias',
        name: 'Agências',
        meta: {
          guards: ['admin'],
          permissions: ["administracao"],
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            { name: 'Agências' },
          ],
        },
        component: () => import('../views/admin/agencias/ListAgencies.vue'),
      },
      {
        path: '/admin/agencias/editar/:id',
        name: 'Editar agência',
        meta: {
          guards: ['admin'],
          permissions: ["administracao"],
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            { name: 'Agências', path: '/admin/agencias' },
            { name: 'Editar agência' },
          ],
        },
        component: () => import('../views/admin/agencias/FormAgencia.vue'),
        props: true,
      },
      {
        path: '/admin/contemplacoes/:cidade',
        name: 'Contempla cidade',
        meta: {
          guards: ['admin'],
          permissions: ["administracao"],
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            { name: 'ProjetosAdmin', path: '/admin/listagem-projetos', alias: 'Projetos' },
            { name: 'Contempla cidade' },
          ],
        },
        component: () => import('../views/admin/contemplacoes/FormContemplacao.vue'),
        props: true,
      },
      {
        path: '/admin/listagem-projetos',
        name: 'ProjetosAdmin',
        meta: {
          guards: ['admin'],
          permissions: "*",
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            { name: 'ProjetosAdmin', alias: 'Projetos' },
          ],
        },
        component: () =>
          import('../views/admin/listagem-projetos/ListListagemProjetos.vue'),
      },
      {
        path: '/admin/listagem-projetos/detalhes/:id',
        name: 'Detalhes Projetos',
        meta: {
          guards: ['admin'],
          permissions: "*",
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            {
              name: 'ProjetosAdmin',
              path: '/admin/listagem-projetos',
              alias: 'Projetos',
            },
            { name: 'Detalhes Projetos' },
          ],
        },
        component: () =>
          import('../views/admin/listagem-projetos/FormListagemProjetos.vue'),
        props: true,
      },
      {
        path: '/admin/listagem-projetos/detalhes/:id/abrir-diligencia',
        name: 'Abrir Diligência Projeto',
        meta: {
          guards: ['admin'],
          permissions: ["administracao"],
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            {
              name: 'ProjetosAdmin',
              path: '/admin/listagem-projetos',
              alias: 'Projetos',
            },
            { name: 'Abrir Diligência Projeto' },
          ],
        },
        component: () =>
          import(
            '../views/admin/listagem-projetos/FormAbrirDiligenciaProjetos.vue'
          ),
        props: true,
      },
      {
        path: '/admin/listagem-projetos/detalhes/:id/avaliar-readequacao',
        name: 'Avaliar Readequação Projeto',
        meta: {
          guards: ['admin'],
          permissions: ["administracao"],
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            {
              name: 'ProjetosAdmin',
              path: '/admin/listagem-projetos',
              alias: 'Projetos',
            },
            { name: 'Avaliar Readequação Projeto' },
          ],
        },
        component: () =>
          import(
            '../views/admin/listagem-projetos/FormAbrirDiligenciaProjetos.vue'
          ),
        props: true,
      },
      {
        path: '/admin/diligencias/:id/:mode',
        name: 'Visualizar Diligência Projeto',
        meta: {
          guards: ['admin'],
          permissions: "*",
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            {
              name: 'ProjetosAdmin',
              path: '/admin/listagem-projetos',
              alias: 'Projetos',
            },
            { name: 'Visualizar Diligência Projeto' },
          ],
        },
        component: () =>
          import(
            '../views/admin/listagem-projetos/FormAbrirDiligenciaProjetos.vue'
          ),
        props: true,
      },
      {
      path: '/admin/readequacao/:id/:mode',
      name: 'Visualizar Readequação do Projeto',
      meta: {
        guards: ['admin'],
        permissions: ["administracao"],
        breadcrumb: [
          { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
          {
            name: 'ProjetosAdmin',
            path: '/admin/listagem-projetos',
            alias: 'Projetos',
          },
          { name: 'Visualizar Readequação do Projeto' },
        ],
      },
      component: () =>
        import(
          '../views/admin/listagem-projetos/FormAbrirDiligenciaProjetos.vue'
        ),
      props: true,
    },
      {
        path: '/admin/listagem-projetos/avaliacoes/:id',
        name: 'Avaliações',
        meta: {
          guards: ['admin'],
          permissions: ["administracao"],
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            {
              name: 'ProjetosAdmin',
              path: '/admin/listagem-projetos',
              alias: 'Projetos',
            },
            { name: 'Avaliações' },
          ],
        },
        component: () => import('../views/admin/avaliacoes/FormAvaliacoes.vue'),
        props: true,
      },
      {
        path: '/coordenador/listagem-projetos/avaliacoes/:id',
        name: 'Avaliações Coordenador',
        meta: {
          guards: ['coordenador'],
          breadcrumb: [
            { name: 'Home', path: '/home', alias: 'Home' },
            {
              name: 'ProjetosCoordenador',
              path: '/coordenador/listagem-projetos',
              alias: 'Projetos',
            },
            { name: 'Avaliações Coordenador', alias: 'Avaliações' },
          ],
        },
        component: () => import('../views/admin/avaliacoes/FormAvaliacoes.vue'),
        props: true,
      },
      {
        path: '/admin/diretrizes',
        name: 'Diretrizes',
        meta: {
          guards: ['admin'],
          permissions: ["administracao"],
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            { name: 'Diretrizes' },
          ],
        },
        component: () => import('../views/admin/diretrizes/ListDiretrizes.vue'),
      },
      {
        path: '/admin/diretrizes/cadastrar',
        name: 'Cadastrar Diretriz',
        meta: {
          guards: ['admin'],
          permissions: ["administracao"],
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            { name: 'Diretrizes', path: '/admin/diretrizes' },
            { name: 'Cadastrar Diretriz' },
          ],
        },
        component: () => import('../views/admin/diretrizes/FormDiretrizes.vue'),
      },
      {
        path: '/admin/diretrizes/editar/:id',
        name: 'Editar Diretriz',
        meta: {
          guards: ['admin'],
          permissions: ["administracao"],
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            { name: 'Diretrizes', path: '/admin/diretrizes' },
            { name: 'Editar Diretriz' },
          ],
        },
        component: () => import('../views/admin/diretrizes/FormDiretrizes.vue'),
        props: true,
      },
      {
        path: '/admin/listagem-entidades',
        name: 'Entidades',
        meta: {
          guards: ['admin'],
          permissions: "*",
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            { name: 'Entidades' },
          ],
        },
        component: () =>
          import('../views/admin/entidades/ListEntidades.vue'),
      },
      {
        path: '/admin/listagem-entidades/detalhes/:id',
        name: 'Detalhes Entidade',
        meta: {
          guards: ['admin'],
          permissions: "*",
          breadcrumb: [
            { name: 'HomeAdmin', path: '/admin/home', alias: 'Home' },
            {
              name: 'Entidades',
              path: '/admin/listagem-entidades',
            },
            { name: 'Detalhes Entidade' },
          ],
        },
        component: () =>
          import('../views/admin/entidades/FormListagemEntidades.vue'),
        props: true,
      },
    ],
  },
];

const routes = [...authRoutes, ...appRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const uiStore = useLoginStore();
  const loggedType = AuthService.getLoggedType();

  const checkAuthentication = async () => {
    const loggedType = AuthService.getLoggedType();

    if (loggedType === 'admin') {
      return uiStore.getToken() != null;
    } else if (loggedType === 'entidade') {
      return await AuthService.checkLoginEntidade();
    } else if (loggedType === 'coordenador') {
      return await AuthService.checkLoginCoordenador();
    }

    return false;
  };

  const isAuthenticated = await checkAuthentication();

  let possuiPermissao = false;
  let loggedTypeCorreto = false;

  if (Array.isArray(to.meta.guards)) {
    loggedTypeCorreto = to.meta.guards.includes(loggedType);
  }

  if (loggedType === 'admin') {
    if (typeof to.meta.permissions === 'string' || Array.isArray(to.meta.permissions)) {
      possuiPermissao = AuthService.usuarioPossuiPermissao(to.meta.permissions)
    }
  }

  if (!loggedTypeCorreto && isAuthenticated) {
    if (loggedType === 'admin') {
      next({ name: 'HomeAdmin' });
      return;
    }

    next({ name: "Home" });
    return
  }

  if (to.name === 'Login' && isAuthenticated) {
    next({ name: 'Home' });
    return;
  }

  if (to.name === 'LoginAdmin' && isAuthenticated) {
    next({ name: 'HomeAdmin' });
    return;
  }

  if (to.path.startsWith('/admin') && isAuthenticated && !possuiPermissao) {
    next({ name: 'HomeAdmin' });
    return;
  }

  if (to.path.startsWith('/admin') && isAuthenticated) {
    next();
    return;
  }

  if (!uiStore.publicRoutes.includes(to.path) && !isAuthenticated) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
