import axios from 'axios';  // Importando o axios
import AuthService from '@/services/AuthService';


// Criar uma nova instância do axios com a URL base
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: true
});

// Usando um interceptor de requisição para definir o cabeçalho de autorização antes de cada requisição
axiosInstance.interceptors.request.use(config => {
  return config;
}, error => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const loginEndpointEntidades = '/api/auth/entidade-login';
      const loginEndpointCoordenadores = '/api/auth/coordenador-login';
      if (error.config && (error.config.url === loginEndpointEntidades || error.config.url === loginEndpointCoordenadores)) {
        // Se for erro de login, apenas retorne o erro sem fazer logout
        return Promise.reject(error);
      }

      // Para outros casos de 401, faça logout
      AuthService.cleanStoredLocalData();
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;