<template>
  <PLoginCallback loginRedirect="HomeAdmin" :name="name" :authCode="authCode" />
</template>

<script>
export default {
  props: {
    name: {
        type: String,
        required: true,
    },
    authCode: {
        type: String,
        required: true,
    },
  }
}
</script>